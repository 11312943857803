* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

.home,
.services,
.products,
.sign-up {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.week4 {
  background-color:#141414;
  color: #fff;
  text-align:center;
}


.week8 {
  background-color:#141414;
  color: #fff;
  text-align:center;
}

.week10 {
  background-color:#141414;
  color: #fff;
  text-align:center;
}

body {
  font-family: sans-serif;
  margin: 2em auto;
  width: 80%;
}
h2 {
  font-weight: bold;
  margin-bottom: 1em;
}
p {
  margin-bottom: 1em;
  line-height: 1.5;
}
.aha {
  font-style: italic;
  margin-top: 1em;
  border-top: 1px solid #ddd;
  padding-top: 0.5em;
}
.takeaways, .why {
  margin-top: 1em;
  list-style: none;
  padding-left: 1em;
}
.takeaways li, .why li {
  margin-bottom: 0.5em;
}

body {
  font-family: sans-serif;
  margin: 2em auto;
  width: 80%;
}
h2 {
  font-weight: bold;
  margin-bottom: 1em;
}
p {
  margin-bottom: 1em;
  line-height: 1.5;
}
.crippling {
  font-style: italic;
  border-top: 1px solid #ddd;
  padding-top: 0.5em;
  margin-bottom: 1em;
}
.winds, .aha, .takeaways, .why {
  margin-top: 1em;
}
.aha li, .takeaways li, .why li {
  margin-bottom: 0.5em;
}